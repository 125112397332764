<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { errorMessages } from './error-center.vue';

const props = defineProps<{
  errors: any[];
  errorCode: number | null;
}>();

const isHidden = ref(false);

const errorMessage = computed(() => {
  switch (Number(props.errorCode)) {
    case 401:
      return 'Du har ikke tilgang til denne dataen.';
    case 403:
      return 'Du har ikke tilgang til denne dataen.';
    case 404:
      return 'Fant ikke data på denne spørringen.';
    case 409:
      return 'Konflikt oppstod.';
    case 500:
      return 'En feil oppstod på serveren.';
    case 503:
      return 'Får ikke kontakt med tjenesten.';
    default:
      return 'En ukjent feil oppstod.';
  }
});

const hasCustomError = computed(() => props.errors.some((error) => !!error?.message));

function formatURL(responseURL: string) {
  return responseURL.includes('dev')
    ? responseURL.replace('https://devapiauth0.dokflyt.no/', '')
    : responseURL.replace('https://apiauth0.dokflyt.no/', '');
}

onMounted(() => {
  setTimeout(() => {
    isHidden.value = true;
    errorMessages.value = [];
  }, 20000);
});
</script>

<template>
  <div class="df-toast" v-if="!isHidden">
    <div class="df-toast__icon">
      <df-icon code="f06a" />
    </div>

    <df-button class="df-toast__action" v-on:click="isHidden = true">
      <template v-slot:icon>
        <df-icon code="f00d" />
      </template>
    </df-button>

    <template v-if="hasCustomError">
      <div class="df-toast__title">Feil oppstod</div>

      <div class="df-toast__description">
        <template v-for="(error, index) in errors" :key="index">
          <div v-for="line in error.message.split('\n')" :key="line">
            {{ line }}
          </div>
        </template>
      </div>
    </template>

    <template v-else>
      <div class="df-toast__title">Feil oppstod ({{ errorCode }})</div>

      <div class="df-toast__description">
        <p>{{ errorMessage }}</p>
        <p>Feilmelding registrert i Dokflyt.</p>
      </div>

      <div class="df-toast__sources">
        <template v-for="(error, index) in errors" :key="index">
          <div class="text--small" v-if="error && error.request">
            {{ formatURL(error.request.responseURL) }}
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<style scoped>
@import '/@shared/styles/visuals.css';

.df-toast {
  @extend %card;
  padding: var(--gap-sm);
  display: grid;
  grid-template-areas: 'icon title action' 'icon description action' 'icon sources sources';
  gap: var(--gap-sm);
  grid-template-columns: max-content 1fr max-content;
  align-items: start;
  width: 100%;
  max-width: 600px;
  overflow-wrap: break-word;
}

.df-toast__icon {
  grid-area: icon;
  font-size: 1.5rem;
  color: var(--color-danger_text);
}

.df-toast__action {
  grid-area: action;
}

.df-toast__title {
  grid-area: title;
  @extend %title-md;
}

.df-toast__description {
  grid-area: description;
}

.df-toast__sources {
  grid-area: sources;
}
</style>
