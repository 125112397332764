import {
  ImportProject,
  Project,
  ProjectCreateForm,
  ProjectFolder,
  ProjectLog,
  ProjectMO,
  ProjectMOProperty,
  ProjectOrder,
  ProjectRequest,
  ProjectStatus,
  ProjectType,
} from './project.types';
import { GeometryType } from '/@types/geometry';
import { Property } from '/@types/properties';
import { cloneDeep, identity } from 'lodash-es';
import { convertMunicipality } from '/@tools/general-utils';
import { mapLogType } from '/@tools/log';

type FromApi = any;

interface MapFileHelperPayload {
  projectId?: number | undefined;
  requestId?: number | undefined;
  category?: 'none';
}

function mapProjectHelper(project: FromApi, archived = false): Project {
  return {
    id: project.Id,
    name: project.Name,
    description: project.Description ? project.Description : '',
    archived: archived,
    type: project.ProjectTypeId,
    typeId: project.ProjectTypeId,
    typeName: project.ProjectTypeName,
    colorId: project.ColorId,
    status: project.ProjectStatus ? project.ProjectStatus.Id : undefined,
    statusGroupId: project.ProjectStatus?.ProjectStatusGroup.Id,
    isExternal: project.IsExternal,
    latitude: project.Latitude,
    longitude: project.Longitude,
    geometry:
      project.Latitude == null || project.Longitude == null
        ? null
        : { type: GeometryType.Point, coordinates: [project.Longitude, project.Latitude] },
    address: project.Address,
    municipality: convertMunicipality(project.Municipality),
    facilityId: project.FacilityId,
    unresolved: project.UnresolvedNotes,
    installer: project.Installer || null,
    caseworker: project.Caseworker,
    caseworkerId: project.CaseworkerId,
    caseworkerEmail: project.CaseworkerEmail,
    customer: project.Customer,
    ref1: project.ReferenceNo1,
    ref2: project.ReferenceNo2,
    folderId: project.ProjectFolder.Id || null,
    folder: project.ProjectFolder.Id
      ? {
          id: project.ProjectFolder.Id,
          name: project.ProjectFolder.Name,
        }
      : project.ProjectTypeId === 3
        ? {
            id: -1,
            name: 'Drift & vedlikehold',
          }
        : {
            id: project.ProjectFolder.Id,
            name: project.ProjectFolder.Name,
          },
    tenant: project.OwnedByCompany
      ? {
          id: project.OwnedByCompany.Id,
          name: project.OwnedByCompany.Name,
        }
      : {
          id: null,
          name: null,
        },
    createdAt: new Date(project.CreatedDate),
    modifiedAt:
      project.LatestModification.DateTimeOffset &&
      project.LatestModification.DateTimeOffset !== '0001-01-01T00:00:00+00:00'
        ? new Date(project.LatestModification.DateTimeOffset)
        : null,
    dueDate:
      project.DueDate && project.DueDate !== '0001-01-01T00:00:00+00:00'
        ? new Date(project.DueDate)
        : null,
    gridownerProjectName: project.GridownerProjectName,
    gridownerAddress: project.GridownerAddress,
    gridownerId: project.GridownerId,
    overviewListEnabled: project.OverviewListEnabled,
    sharedWithCompanies: project.SharedWithCompanies,
    departmentId: project.DepartmentId,
    department: project.Department?.Name,
    contactId: project.ContactId,
    contactName: project.ContactName,
    contactPhone: project.ContactMobile,
    contactEmail: project.ContactEmail,
    contactCompanyId: project.ContactCompanyId,
    contactCompanyName: project.ContactCompanyName,
    registrationsLocked: project.RegistrationsLockedAt === null ? false : true,
    priorityId: project.PriorityId,
    priorityName: project.PriorityName,
  };
}

function mapProjectToForm(project: Project): ProjectCreateForm {
  const p = cloneDeep(project);

  return {
    // page 1
    contactId: p.contactId,
    contactCompanyId: p.contactCompanyId,

    // page 2
    folderId: p.folder.id !== 0 ? p.folder.id : null,
    installer: p.installer,
    caseworkerId: p.caseworkerId,
    facilityId: p.facilityId,
    description: p.description,
    dueDate: p.dueDate,

    // page 3
    address: p.address,
    municipality: p.municipality,
    latitude: p.latitude,
    longitude: p.longitude,

    // page 4
    name: p.name,
    colorId: p.colorId,
    overviewListEnabled: p.overviewListEnabled,
    ref1: p.ref1,
    ref2: p.ref2,
    departmentId: p.departmentId,

    customer: p.customer,
    gridownerProjectName: p.gridownerProjectName,

    typeId: p.type,
    priorityId: p.priorityId,
  };
}

function mapProjectDetails(details: FromApi, projectid: number): Map<number, Property> {
  const setValue = (val: FromApi, type: string) => {
    switch (type) {
      case 'datetime': {
        return val && val !== '0001-01-01T00:00:00+00:00' ? new Date(val) : undefined;
      }
      default:
        return val;
    }
  };

  return new Map(
    details
      .map((detail: FromApi) => [
        detail.Id,
        {
          id: detail.Id,
          uniqueId: `${projectid}-${detail.Id}`,
          key: detail.Key,
          label: detail.Key,
          guid: detail.Guid,
          name: detail.Name,
          description: detail.Description,
          value: setValue(detail.Value, detail.DataType),
          valueId: detail.ValueId,

          categoryName: detail.CategoryName,

          shortName: detail.ShortName,

          type: detail.DataType,
          format: detail.InputFormat,
          valueListGuid: detail.ValueListGuid,
          valueListId: detail.ValueListId,

          sortIndex: detail.SortIndex,

          default: detail.ShowInTableActive,
          showInItemPage: detail.ShowInItemPage,

          hideInTable: detail.HideInTable,
          readonly: detail.ReadOnly,
        },
      ])
      .sort((a, b) => a[1].sortIndex - b[1].sortIndex),
  );
}

function mapProjectType(t: FromApi, moduleId: number, tenantId: number): ProjectType {
  return {
    id: t.Id,
    moduleId,
    tenantId,
    name: t.Name,
    description: t.Description,
    hidden: t.Hidden,
    activeForTenant: t.ActiveForTenantIds,

    typeFeatures: t.Features,
    checklistTemplateId: t.ChecklistTemplateId || 114, // NOTE: Temporary use test checklist since API doesnt provide

    typeProperties: new Map([
      ...t.ProjectTypeProperties.map((p) => [
        p.Id,
        {
          id: p.Id,
          name: p.Name,
          shortName: p.ShortName,
          valueListGuid: p.ValueListGuid,
          type: p.DataType,
          showInTableHead: p.ShowInTableHead,
          hideInTable: p.HideInTable,
          readonly: p.ReadOnly,
          dataType: p.DataType,
        },
      ]),
    ]),

    contactRoles: new Map([
      ...t.ContactRoles.map((r) => [
        r.Id,
        {
          id: r.Id,
          name: r.Name,
          isCompanySpecific: r.IsCompanySpecific,
        },
      ]),
    ]),

    labelKeys: new Map([
      ...t.LabelKeys.map((l) => [
        l.Id,
        {
          id: l.Id,
          name: l.Name,
          sortIndex: l.SortIndex,
        },
      ]),
    ]),

    reportTemplates: new Map([
      ...t.ReportTemplates.map((template) => [
        template.Id,
        {
          name: template.Name,
          description: template.Description,
          category: template.Category,
          key: template.Key,
        },
      ]),
    ]),
  };
}

function mapRequestHelper(request: FromApi, projectId: number): ProjectRequest {
  const id = request.Id || null;
  const catName = request.RequestCategory.Name || null;
  const address = request.RequestedAddress || null;

  const name = [catName, address].reduce((a, v) => (v ? `${a} - ${v}` : a), id);

  return {
    id: request.Id,
    projectId,
    name,
    address: request.RequestedAddress,
    user: {
      name: [request.RequestedByFirstName, request.RequestedByLastName].filter(identity).join(' '),
      phone: request.RequestedByPhoneNumber,
    },
    owner: request.TenantName ? request.TenantName : '',
    ownerId: request.TenantId || null,
    report: request.Json
      ? {
          area: request.Json.area,
          municipality: request.Json.county,
          sources: request.Json.source,
        }
      : {},
    comment: request.Comment,
    description: request.Description,
    category: request.RequestCategory.Id,
    createdAt: new Date(request.CreatedAt),
    modifiedAt: request.ModifiedAt ? new Date(request.ModifiedAt) : undefined,
    maintenanceObjects: request.MaintenanceObjects?.map((mo) => ({
      id: mo.Id,
      customId: mo.CustomId,
      controlCycle: mo.ControlCycles,
    })),
  };
}

function mapFileHelper(
  { projectId = undefined, requestId = undefined, category = 'none' }: MapFileHelperPayload,
  file,
) {
  const contextQuery = requestId
    ? `context=requests&contextId=${requestId}`
    : `context=projects&contextId=${projectId}`;
  return {
    id: file.Id,
    guid: file.Guid,
    projectId: projectId,
    requestId: requestId,
    structureType: file.StructureType,
    structureGuid: file.StructureGuid,
    type: file.ContentType,
    name: file.OriginalFilename,
    detail: '',
    size: file.ByteSize,
    category,
    categoryId: file.FileCategoryId,
    createdAt: new Date(file.UploadedAt),
    direction: file.Direction,
    coords:
      file.Latitude && file.Longitude
        ? {
            lat: file.Latitude,
            lng: file.Longitude,
          }
        : null,
  };
}

function mapChecklistHelper(checklist: FromApi) {
  return {
    id: checklist.Id,
    guid: checklist.ChecklistGuid,
    name: checklist.Name,
    revision: checklist.Revison,
    projectId: checklist.ProjectId,
    reference: checklist.ReferenceName,
    referenceLabel: checklist.LabelReferenceName,
    folder: checklist.Folder,
    user: {
      name: checklist.ModifiedByUsername,
    },
    modifiedAt: new Date(checklist.ModifiedDateTime),
    modifiedBy: {
      name: checklist.ModifiedByUsername,
    },
    statusId: checklist.StatusId,
    showStatus: checklist.ShowStatus,
    templateId: checklist.TemplateId,
  };
}

function mapPhotoReportHelper(photoReport: FromApi) {
  return {
    id: photoReport.Id,
    name: photoReport.Name,
    type: photoReport.Type,
    projectId: photoReport.ProjectId,
    modifiedAt: new Date(photoReport.ModifiedDateTime),
    modifiedBy: {
      id: photoReport.ModifiedByUserId,
      name: photoReport.ModifiedByUsername,
    },
  };
}

function mapStatus(status: FromApi, moduleIds: number[]): ProjectStatus {
  return {
    id: status.Id,
    name: status.Name,
    description: status.Description,
    projectTypeIds: status.ProjectTypes.map(({ Id }) => Id),
    projectTypeDescriptions: new Map(
      status.ProjectTypes.map(({ Id, Description }) => [Id, Description || status.Description]),
    ),
    moduleIds,
    sortIndex: status.SortIndex,
    colorId: status.ColorId,
    projectStatusGroupId: status.ProjectStatusGroupId,
    active: status.Active,
    hiddenInProjectTypeIds: status.HiddenInProjectTypeIds,
    statusGroupId: status.ProjectStatusGroupId,
  };
}

function mapLogHelper(log: FromApi): ProjectLog {
  return {
    guid: log.Guid,
    group: null,
    projectId: log.ProjectId,
    user: {
      name: log.PerformedByUserName,
    },
    createdAt: log.Date,
    title: log.ProjectName,
    description: log.Description,
    type: mapLogType(log.Type),
    // TODO: Move to a slot in component
    link: `/projects/${log.ProjectId}`,
  };
}

function mapOrder(projectId: number, order: FromApi): ProjectOrder {
  return {
    id: order.Id,
    guid: order.Guid,
    projectId,
    downloadUrl: order.DownloadUrl,
    status: order.Status,
    size: order.ByteSize,
    validTo: order.ValidTo,
    createdAt: new Date(order.CreatedAt),
    createdBy: order.CreatedByUserId,
  };
}

function mapProjectFolder(folder: FromApi): ProjectFolder {
  return {
    id: folder.Id,
    name: folder.Name,
    disabled: folder.Disabled,
    isFavorite: folder.IsFavorite,
    geometry: folder.Geometry,
    departmentId: folder.DepartmentId,
    department: folder.DepartmentName,
    contactCompanyId: folder.ContactCompanyId,
    contactCompany: folder.ContactCompanyName,
  };
}

function mapImportProject(p: FromApi, processed: boolean): ImportProject {
  return {
    id: p.Id,
    name: p.Name,
    folderName: p.FolderName,
    externalProjectId: p.ExternalProjectId,
    externalCreatedDate: p.ExternalCreatedDate,
    description: p.Description,
    externalRecordId: p.ExternalRecordId,
    customerName: p.CustomerName,
    address: p.Address,
    municipality: p.Municipality,
    caseworker: p.Caseworker,
    installer: p.Installer,

    createdDate: p.CreatedDate,
    processedDate: p.ProcessedDate,
    processedByUserId: p.ProcessedByUserId,
    processedByUserName: p.ProcessedByUserName,
    processedToProjectId: p.ProcessedToProjectId,
    processed: processed,
    moduleId: p.ModuleId,

    lat: null,
    lng: null,

    projectTypeId: p.ProjectTypeId,
    reference2: p.Reference2,
    dueDate: p.DueDate,
    contactCompanyId: p.ContactCompanyId,
    municipalityId: p.MunicipalityId,
    contactId: p.ContactId,
    departmentId: p.DepartmentId,

    ref1: p.ProjectContractNumber, // external id from integration like SAP
    ref2: p.ExternalProjectId, // external id from integration like dynamics | elsmart
    source: p.Source,
    properties: p.PropertyList?.map((p) => ({ label: p.Label, value: p.Value })) || [],
  };
}

function mapProjectMO(p: FromApi): ProjectMO {
  return {
    id: p.Id,
    properties: new Map<number, ProjectMOProperty>(
      p.Properties?.map((req: any) => [
        req.Id,
        {
          id: req.Id,
          key: req.Key,
          value: req.Value,
        },
      ]),
    ),
  };
}

export {
  mapProjectHelper,
  mapProjectToForm,
  mapProjectDetails,
  mapProjectType,
  mapRequestHelper,
  mapFileHelper,
  mapChecklistHelper,
  mapPhotoReportHelper,
  mapStatus,
  mapLogHelper,
  mapOrder,
  mapProjectFolder,
  mapImportProject,
  mapProjectMO,
};
