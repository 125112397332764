import { ref } from 'vue';
import { defineStore } from 'pinia';
import { FlexiSaldo, ProfileForm, SettingsForm, User } from './user.types';
import { GeometryType } from '/@types/geometry';
import { api, genericApiErrorHandler } from '/@tools/api';
import { client } from '/@shared/plugins/auth0';
import { intercomAppId } from '/@tools/config';
import { clearGoldPartnerStorage, goldPartners } from '/@features/tenant/tenant.tools';
import { offlineOverviewProjects } from '/@tools/offline';
import { appDb } from '/@tools/database';
import * as Sentry from '@sentry/vue';
import { sendError } from '/@plugins/sentry';
import { mapProjectHelper } from '/@features/project/project.mappers';

let resolveUser: (value: unknown) => void;
export const awaitUser2 = new Promise((resolve) => (resolveUser = resolve));

export const useUserStore = defineStore('user', () => {
  const user = ref<User>({
    id: 0,
    guid: '',
    auth0Id: '',
    email: '',
    name: '',
    firstName: '',
    lastName: '',
    birthdate: '',
    hmsCardNo: '',
    employeeNumber: '',
    phoneNumber: '',
    intercomIdentity: '',
    departmentId: null,
    department: null,
    signatureFileGuid: '',

    betaUser: false,
    showStatusNotificationPage: false,

    settings: {
      showVeilysProjectMapReportErrorMO: false,
      showEnergiProjectMapReportErrorMO: false,
      showElektroProjectMapReportErrorMO: false,
      showFinishedProjects: false,
      showStatusNotificationPage: false,
      betaUser: false,
    },

    tenant: {
      id: 0,
      name: '',
      guid: '',
      municipalities: [],
      geometry: {
        type: GeometryType.Point,
        coordinates: [9.7877546, 61.5043329],
      },
      ekomAuthorizationNumber: null,
      departments: new Map(),

      companyLogoGuid: null,
      address: null,
      postalNumber: null,
      postalPlace: null,
      phone: null,
      orgNumber: null,
      industries: [],
    },

    roles: [],

    modules: [],

    permissions: [],

    favoriteProjects: new Map(),

    favoriteProjectTypes: new Map(),
    favoriteProjectFolders: new Map(),
    hiddenModuleIds: [],

    qualifications: new Map(),
    goldpartnerIds: [],
  });

  let retryCount = 0;

  function loadUser(skipCache = false): Promise<void> {
    return api
      .get(`/shared/users/current`, { params: { skipCache } })
      .then(({ data: d }) => {
        // Clear goldpartners for tenant 8 = laje
        if (d.Tenant.Id === 8 && goldPartners.value?.size > 0) {
          goldPartners.value = new Set();
          clearGoldPartnerStorage();
        }

        const currentUser: User = {
          id: d.Id,
          guid: d.Guid,
          auth0Id: d.Auth0Id,
          email: d.Email,
          name: d.FullName,
          firstName: d.FirstName,
          lastName: d.LastName,
          birthdate: d.DateOfBirth,
          hmsCardNo: d.HmsCardNumber,
          employeeNumber: d.EmployeeNumber,
          phoneNumber: d.Mobile,
          intercomIdentity: d.IntercomIdentity,
          departmentId: d.Department?.Id,
          department: d.Department?.Name,
          signatureFileGuid: d.SignatureFileGuid,

          betaUser: d.Settings.BetaUser,
          showStatusNotificationPage: d.Settings.ShowStatusNotificationPage,

          settings: {
            showVeilysProjectMapReportErrorMO: d.Settings.ShowVeilysProjectMapReportErrorMO,
            showEnergiProjectMapReportErrorMO: d.Settings.ShowEnergiProjectMapReportErrorMO,
            showElektroProjectMapReportErrorMO: d.Settings.ShowElektroProjectMapReportErrorMO,
            showStatusNotificationPage: d.Settings.ShowStatusNotificationPage,
            showFinishedProjects: d.Settings.ShowFinishedProjects,
            betaUser: d.Settings.BetaUser,
          },

          tenant: {
            id: d.Tenant.Id,
            name: d.Tenant.Name,
            guid: d.Tenant.Guid,
            municipalities: d.Tenant.Municipalities || [],
            geometry:
              d.Tenant.Geometry == null
                ? {
                    type: GeometryType.Point,
                    coordinates: [9.7877546, 61.5043329],
                  }
                : d.Tenant.Geometry,
            ekomAuthorizationNumber: d.Tenant.EkomAuthorizationNumber,
            departments: new Map(d.Tenant.Departments.map((d) => [d.Id, d.Name])),

            companyLogoGuid: d.Tenant.CompanyLogoGuid,
            address: d.Tenant.Address,
            postalNumber: d.Tenant.PostalNumber,
            postalPlace: d.Tenant.PostalPlace,
            phone: d.Tenant.PhoneNumber,
            orgNumber: d.Tenant.OrgNumber,
            industries: d.Tenant.Industries.map((i) => ({
              id: i.Id,
              name: i.Name,
              description: i.Description,
            })),
          },

          roles: d.Roles.map(({ Id, Name }) => ({ id: Id, name: Name })),

          modules: d.Tenant.Modules.map(({ Id, Name }) => ({ id: Id, name: Name })),

          permissions: d.Permissions,

          favoriteProjects: new Map(),

          favoriteProjectTypes: new Map(),
          favoriteProjectFolders: new Map(),
          hiddenModuleIds: d.BlacklistedModuleIds,

          qualifications: new Map(d.Qualifications.map((i) => [i.Id, i.Name])),
          goldpartnerIds: [],
        };

        user.value = currentUser;
        resolveUser(currentUser);

        // populate plugins

        Sentry.setUser({
          id: currentUser.id,
          email: currentUser.email,
          username: currentUser.name,
          tenantId: currentUser.tenant.id,
          tenantName: currentUser.tenant.name,
        });

        //@ts-expect-error intercom lacks ts
        window.intercomSettings = {
          app_id: intercomAppId,
          name: currentUser.name,
          email: currentUser.email,
          user_id: currentUser.id,
          hide_default_launcher: true,
        };

        //@ts-expect-error intercom lacks ts
        window.Intercom('boot', {
          app_id: intercomAppId,
          user_id: currentUser.id,
          user_hash: currentUser.intercomIdentity,
          name: currentUser.name,
          email: currentUser.email,
          phone: currentUser.phoneNumber,
          company: {
            id: currentUser.tenant.id,
            name: currentUser.tenant.name,
            // custom company data
            Moduler: `[${d.Tenant.Modules.map(({ Name }) => Name).join(', ')}]`.slice(0, 128),
          },
          // custom data
          Roller: `[${d.Roles.map(({ Name }) => Name).join(', ')}]`.slice(0, 128),
          Beta: currentUser.betaUser,
        });

        // save user in storage for use with other storage items
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
      })

      .catch(async (error) => {
        genericApiErrorHandler(error);

        if (retryCount <= 3) {
          retryCount++;
          return loadUser(true);
        }

        // AD user check
        // will check if user exist in auth0 and DF, if only 1 it will redirect to a page explaining user is not in DF
        const clientUser = await client.getUser();
        if (!clientUser) throw 'Could not get user from client';
        return api
          .get('/shared/users/auth0exists', { params: { auth0Id: clientUser.sub } })
          .then(({ data }) => {
            if (!data.Exists) {
              sendError('azure-error', { error });
              throw 'azure-error';
            }
            return genericApiErrorHandler(error);
          });
      });
  }

  function loadFlexiSaldo(): Promise<Map<number, FlexiSaldo>> {
    return api
      .get(`shared/users/current/flexisaldo`)
      .then(({ data }) => {
        return new Map(
          data.map((item: any, index: number) => [
            index,
            { balance: item.Balance, description: item.Description },
          ]),
        );
      })
      .catch(genericApiErrorHandler);
  }

  function updateProfile(form: ProfileForm) {
    return api
      .put('/userprofile/profile', {
        FirstName: form.firstName,
        LastName: form.lastName,
        Mobile: form.phoneNumber,
        DateOfBirth: form.birthdate ? new Date(form.birthdate).toISOString().slice(0, 10) : null,
        HmsCardNumber: form.hmsCardNumber,
        EmployeeNumber: form.employeeNumber,
        QualificationIds: form.qualificationIds,
      })
      .then(() => loadUser(true))
      .catch(genericApiErrorHandler);
  }

  function updateSettings(userId: number, form: SettingsForm) {
    return api
      .put(`/userprofile/${userId}/settings`, {
        ShowVeilysProjectMapReportErrorMO: form.showVeilysProjectMapReportErrorMO,
        ShowEnergiProjectMapReportErrorMO: form.showEnergiProjectMapReportErrorMO,
        ShowElektroProjectMapReportErrorMO: form.showElektroProjectMapReportErrorMO,
        ShowFinishedProjects: form.showFinishedProjects,
        ShowStatusNotificationPage: form.showStatusNotificationPage,
        BetaUser: form.betaUser,
      })
      .then(() => loadUser(true))
      .catch(genericApiErrorHandler);
  }

  function loadFavoriteProjects() {
    return api
      .get('/userprofile/favoriteprojects')
      .then(({ data }) => {
        user.value.favoriteProjects = new Map(
          data.map((i) => [
            i.ProjectId,
            { name: i.ProjectName, typeId: i.ProjectTypeId, favoritedAt: new Date(i.FavoritedAt) },
          ]),
        );

        offlineOverviewProjects(data);
        return user.value.favoriteProjects;
      })
      .catch(genericApiErrorHandler);
  }

  function addFavoriteProject(projectId: number) {
    return api
      .post('/userprofile/favoriteprojects', {}, { params: { projectId } })
      .then(() =>
        api.get(`/v1/projects/${projectId}`).then(({ data }) => {
          const project = mapProjectHelper(data);
          appDb.projects.put(project);

          user.value.favoriteProjects.set(project.id, {
            name: project.name,
            typeId: project.type,
            favoritedAt: new Date(),
          });
        }),
      )
      .catch(genericApiErrorHandler);
  }

  function removeFavoriteProject(projectId: number) {
    return api
      .delete(`/userprofile/favoriteprojects/${projectId}`)
      .then(() => {
        user.value.favoriteProjects.delete(projectId);
      })
      .catch(genericApiErrorHandler);
  }

  function saveSignature(fileGuid: string, signature: Blob) {
    const file = new File([signature], 'signature.png');
    return api
      .postForm(`/userfiles/${fileGuid}`, {
        file,
        isSignature: true,
      })
      .then(() => loadUser(true))
      .catch(genericApiErrorHandler);
  }

  function loadFavoriteProjectTypes() {
    return api
      .get('/userprofile/favoriteprojecttypes')
      .then(({ data }) => {
        user.value.favoriteProjectTypes = new Map(
          data.map((i) => [i.ProjectTypeId, i.ProjectTypeName]),
        );
      })
      .catch(genericApiErrorHandler);
  }

  function addFavoriteProjectType(typeId: number) {
    return api
      .post('/userprofile/favoriteprojecttypes', {}, { params: { projectTypeId: typeId } })
      .then(() => loadFavoriteProjectTypes())
      .catch(genericApiErrorHandler);
  }

  function removeFavoriteProjectType(typeId: number) {
    return api
      .delete(`/userprofile/favoriteprojecttypes/${typeId}`)
      .then(() => {
        user.value.favoriteProjectTypes.delete(typeId);
      })
      .catch(genericApiErrorHandler);
  }

  function loadFavoriteProjectFolders() {
    return api
      .get('/userprofile/favoriteprojectfolders')
      .then(({ data }) => {
        user.value.favoriteProjectFolders = new Map(
          data.map((i) => [i.ProjectFolderId, i.ProjectFolderName]),
        );
      })
      .catch(genericApiErrorHandler);
  }

  function addFavoriteProjectFolder(folderId: number) {
    return api
      .post('/userprofile/favoriteprojectfolders', { ProjectFolderId: folderId })
      .then(() => loadFavoriteProjectFolders())
      .catch(genericApiErrorHandler);
  }

  function removeFavoriteProjectFolder(folderId: number) {
    return api
      .delete(`/userprofile/favoriteprojectfolders/${folderId}`)
      .then(() => {
        user.value.favoriteProjectFolders.delete(folderId);
      })
      .catch(genericApiErrorHandler);
  }

  return {
    user,
    loadUser,
    updateProfile,
    updateSettings,
    loadFlexiSaldo,

    loadFavoriteProjects,
    addFavoriteProject,
    removeFavoriteProject,

    saveSignature,

    loadFavoriteProjectTypes,
    addFavoriteProjectType,
    removeFavoriteProjectType,

    loadFavoriteProjectFolders,
    addFavoriteProjectFolder,
    removeFavoriteProjectFolder,
  };
});

// for use in table/map cmp - DO NOT USE ELSEWHERE!

export function loadFavoriteProjectFolders(): Promise<Map<number, string>> {
  return api
    .get('/userprofile/favoriteprojectfolders')
    .then(({ data }) => new Map(data.map((i) => [i.ProjectFolderId, i.ProjectFolderName])))
    .catch(genericApiErrorHandler);
}
