import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { api, createQueryParams, genericApiErrorHandler } from '/@tools/api';
import { compare } from '/@tools/intl';
import {
  Article,
  GoldPartner,
  Gridowner,
  LoadCaseworkersOptions,
  Partner,
  RequestCategoryTenant,
  SubcontractorInstaller,
  Tenant,
  TenantArchiveSettings,
  TenantFavoriteUserForm,
  TenantFavoriteUsers,
  TypeGroup,
} from './tenant.types';
import { PartnerPermissions } from '/@shared/types/ids';
import { mapArticle } from './tenant.mappers';

type FromAPI = any;

let resolveTenant: (val?: any) => any;
export const awaitTenant = new Promise((resolve) => (resolveTenant = resolve));

function mapTenant(t: FromAPI): Tenant {
  return {
    id: t.Id,
    name: t.Name,
    publicMessage: t.PublicMessage,
    gridownerId: t.GridownerId,
    gridowner: t.Gridowner,
    features: t.TenantFeatures,
    counts: {
      tenantFiles: t.Counts.TenantFiles,
      maxPartners: t.MaxPartners,
      projects: {
        activeShared: t.Counts.Project.ActiveShared,
        internalControl: t.Counts.Project.InternalControl,
        internalControlShared: t.Counts.Project.InternalControlShared,
      },
      byggestrom: {
        incoming: t.Counts.Byggestrom.Innkommende,
      },
    },
    projectNamePattern: t.ProjectNamePattern,
  };
}

function mapPartner(partner: FromAPI, employer = false): Partner {
  return {
    id: partner.Id,
    guid: partner.Guid,
    name: partner.Name,
    orgNr: partner.OrgNumber,
    geometry: partner.Geometry,
    municipalities: partner.Municipalities,
    permissionsReceived: employer
      ? partner.PermissionGuidsFromEmployer
      : partner.PermissionGuidsFromSubcontractor,
    permissionsGiven:
      partner.PermissionGuidsFromEmployer || partner.PermissionGuidsFromSubcontractor,
    permissionsFromEmployer: partner.PermissionGuidsFromEmployer,
    permissionsFromSubcontractor: partner.PermissionGuidsFromSubcontractor,
    departments: new Map(partner.Departments.map((d) => [d.Id, d.Name])),
    maxUsers: partner.MaxUsers || 5, // backend default is 5
  };
}

function mapRequestCategoryTenant(rct: FromAPI): RequestCategoryTenant {
  return {
    id: rct.Id,
    tenantId: rct.TenantId,
    tenantName: rct.Name,
    requestCategoryId: rct.RequestCategoryId,
    requestCategoryName: rct.RequestCategoryName,
    createProject: rct.CreateProjectFromRequest,
    departmentId: rct.DepartmentId,
  };
}

export const useTenantStore = defineStore('tenant', () => {
  const tenant = ref<Tenant>({
    id: null,
    name: '',
    publicMessage: '',
    gridownerId: null,
    gridowner: '',
    features: [],
    counts: {
      tenantFiles: 0,
      maxPartners: null,
      projects: {
        activeShared: 0,
        internalControl: 0,
        internalControlShared: 0,
      },
      byggestrom: {
        incoming: 0,
      },
    },
    projectNamePattern: null,
  });

  function loadTenant() {
    return api
      .get('/shared/users/currenttenant')
      .then(({ data }) => {
        tenant.value = mapTenant(data);
        resolveTenant();
      })
      .catch(genericApiErrorHandler);
  }

  function updatePublicMessage(tenantId: number, message: string) {
    return api
      .put(`/tenants/${tenantId}/publicmessage`, JSON.stringify(message), {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        tenant.value.publicMessage = message;
      })
      .catch(genericApiErrorHandler);
  }

  const departments = ref<Map<number, string>>(new Map());

  function loadDepartments({
    tenantIds = [],
    skipStore = false,
  }: { tenantIds?: number[]; skipStore?: boolean } = {}): Promise<Map<number, string>> {
    const query = createQueryParams(new Map([['tenantIds', tenantIds]]));
    return api
      .get('/departments', { params: query })
      .then(({ data }) => {
        if (!skipStore) {
          departments.value = new Map(data.map((i) => [i.Id, i.Name]));
        }
        return departments.value;
      })
      .catch(genericApiErrorHandler);
  }

  const caseworkers = ref<Map<number, string>>(new Map());

  function loadCaseworkers(): Promise<Map<number, string>> {
    return api
      .get(`/v1/Project/Caseworkers`)
      .then(({ data }) => {
        const mapped = new Map(data.map((i: any) => [i.UserId, i.FullName]));
        //@ts-expect-error cannot assert type from map
        caseworkers.value = mapped;
        return mapped;
      })
      .catch(genericApiErrorHandler);
  }

  function loadCaseworkers2(
    options: LoadCaseworkersOptions,
  ): Promise<Map<number, { name: string; disabled: boolean }>> {
    const queries = createQueryParams(new Map(), { useGoldPartners: options.useGoldPartners });
    if (options.includeDisabled) {
      queries.append('includedisabled', 'true');
    }
    queries.append('sharedprojects', String(options.shared));
    queries.append('moduleid', String(options.moduleId));
    queries.append('statusgroupid', String(options.statusGroupId));

    return api
      .get(`/v2/projects/caseworkers?${queries}`, { signal: options.signal })
      .then(({ data }) => {
        const caseworkers = data.map(({ Id, Name, Disabled }) => [
          Id,
          { name: Name, disabled: Disabled },
        ]);
        return new Map(caseworkers);
      })
      .catch(genericApiErrorHandler);
  }

  const installers = ref<Map<number, string>>(new Map());

  function loadInstallers(): Promise<Map<number, string>> {
    return api
      .get(`/v1/Project/Installers`)
      .then(({ data }) => {
        installers.value = new Map(data.map((i: any) => [i.UserId, i.FullName]));
        return installers.value;
      })
      .catch(genericApiErrorHandler);
  }

  const users = ref<Map<number, string>>(new Map());

  function loadUsers(): Promise<Map<number, string>> {
    return api
      .get(`/tenants/users`)
      .then(({ data }) => {
        users.value = new Map(
          data.sort((a, b) => compare(a.Name, b.Name)).map((user) => [user.UserId, user.Name]),
        );
        return users.value;
      })
      .catch(genericApiErrorHandler);
  }

  const gridowners = ref<Map<number, Gridowner>>(new Map());

  const gridownerByTenantId = computed(
    () => (tenantId: number) =>
      [...gridowners.value.values()].find((g) => g.tenantId === tenantId) || null,
  );

  function loadGridowners() {
    return api
      .get(`/gridowners`)
      .then(({ data }) => {
        const sortedGridowners = data.sort((a, b) => {
          const aIsFav = a.IsFavorite;
          const bIsFav = b.IsFavorite;

          if (aIsFav && !bIsFav) return -1;
          if (!aIsFav && bIsFav) return 1;
          return compare(a.Name, b.Name);
        });

        sortedGridowners.forEach((gridowner) => {
          gridowners.value.set(gridowner.Id, {
            id: gridowner.Id,
            name: gridowner.Name,
            address: gridowner.Address,
            tenantId: gridowner.TenantId,
            isFavorite: gridowner.IsFavorite,
          });
        });
      })
      .catch(genericApiErrorHandler);
  }

  const employers = ref<Map<number, Partner>>(new Map());

  function loadEmployers(tenantIds: number[] = []): Promise<Map<number, Partner>> {
    return api
      .get(`/employers`, { params: { tenantIds } })
      .then(({ data }) => {
        data.forEach((e) => {
          employers.value.set(e.Id, mapPartner(e, true));
        });
        return employers.value;
      })
      .catch(genericApiErrorHandler);
  }

  const subcontractors = ref<Map<number, Partner>>(new Map());

  function loadSubcontractors(tenantIds: number[] = []): Promise<Map<number, Partner>> {
    return api
      .get(`/subcontractors`, { params: { tenantIds } })
      .then(({ data }) => {
        data.forEach((e) => {
          subcontractors.value.set(e.Id, mapPartner(e));
        });
        return subcontractors.value;
      })
      .catch(genericApiErrorHandler);
  }

  // only for user-visible permissions ex. admin partners
  const selectablePermissions = new Map([
    [
      PartnerPermissions.ProjectDocumentsReadAll,
      {
        name: 'Les alle prosjektdokumenter',
        description: 'Gir partner tilgang til alle dokumenter på delte prosjekter',
      },
    ],

    [
      PartnerPermissions.ProjectChecklistsReadAll,
      {
        name: 'Les alle sjekklister',
        description: 'Gir partner tilgang til alle sjekklister på delte prosjekter',
      },
    ],
  ]);

  const hasPartnerPermission = computed(() => (partnerId: number, permissions: string[]) => {
    const partner = partners.value.get(partnerId);
    const partnerPermissions = partner ? partner.permissionsReceived : [];

    return [...partnerPermissions].some((p) =>
      [...permissions, PartnerPermissions.DepartmentPartner].includes(p),
    );
  });

  function addPermission(partnerId: number, employer: boolean, permissionGuid: PartnerPermissions) {
    const endpoint = employer
      ? `/employers/${partnerId}/permissions`
      : `/subcontractors/${partnerId}/permissions`;

    return api.post(endpoint, { PermissionGuids: [permissionGuid] }).catch(genericApiErrorHandler);
  }

  function removePermission(partnerId: number, employer: boolean, permissionGuid: string) {
    const endpoint = employer
      ? `/employers/${partnerId}/permissions/${permissionGuid}`
      : `/subcontractors/${partnerId}/permissions/${permissionGuid}`;

    return api.delete(endpoint).catch(genericApiErrorHandler);
  }

  const partners = computed(
    () =>
      new Map(
        [...employers.value, ...subcontractors.value].sort((a, b) => compare(a[1].name, b[1].name)),
      ),
  );

  function loadPartners(tenantIds: number[] = []): Promise<Map<number, Partner>> {
    return Promise.all([loadEmployers(tenantIds), loadSubcontractors(tenantIds)]).then(
      (partners) => new Map(partners.flatMap((i) => [...i])),
    );
  }

  const tenantFavoriteUsers = ref<Map<string, TenantFavoriteUsers>>(new Map());

  function loadTenantFavoriteUsers() {
    return api
      .get(`/tenantfavoriteusers`)
      .then(({ data }) => {
        data.forEach((user: any) => {
          tenantFavoriteUsers.value.set(`${user.UserId}+${user.SharedWithTenantId}`, {
            userId: user.UserId,
            fullName: user.FullName,
            description: user.Description,
            sharedWithTenantId: user.SharedWithTenantId,
            autoMention: user.AutoMention,
            autoEmail: user.AutoEmail,
          });
        });
      })
      .catch(genericApiErrorHandler);
  }

  function addTenantFavoriteUser({
    tenantId,
    userId,
    description,
    autoMention,
    autoEmail,
  }: TenantFavoriteUserForm) {
    return api
      .post(`/tenantfavoriteusers/tenant/${tenantId}/users/${userId}`, {
        Description: description,
        AutoMention: autoMention,
        AutoEmail: autoEmail,
      })
      .then(() => loadTenantFavoriteUsers())
      .catch(genericApiErrorHandler);
  }

  function updateTenantFavoriteUser({
    tenantId,
    userId,
    description,
    autoMention,
    autoEmail,
  }: TenantFavoriteUserForm) {
    return api
      .put(`/tenantfavoriteusers/tenant/${tenantId}/users/${userId}`, {
        Description: description,
        AutoMention: autoMention,
        AutoEmail: autoEmail,
      })
      .then(() => loadTenantFavoriteUsers())
      .catch(genericApiErrorHandler);
  }

  function deleteTenantFavoriteUser(tenantId: number, userId: number) {
    return api
      .delete(`/tenantfavoriteusers/tenant/${tenantId}/users/${userId}`)
      .then(() => {
        tenantFavoriteUsers.value.delete(`${userId}+${tenantId}`);
      })
      .catch(genericApiErrorHandler);
  }

  function addSubcontractor(tenantGuid: string) {
    return api
      .post(`/subcontractors`, { TenantGuid: tenantGuid })
      .then(() => loadPartners())
      .catch(genericApiErrorHandler);
  }

  function removeSubcontractor(subcontractorId: number) {
    return api
      .delete(`/subcontractors/${subcontractorId}`)
      .then(() => {
        subcontractors.value.delete(subcontractorId);
      })
      .catch(genericApiErrorHandler);
  }

  function loadSubcontractorById(partnerId: number) {
    return api
      .get(`/subcontractors/${partnerId}`)
      .then(({ data }) => {
        subcontractors.value.set(data.Id, mapPartner(data));
      })
      .catch(genericApiErrorHandler);
  }

  const subcontractorInstallers = ref<Map<number, SubcontractorInstaller>>(new Map());

  function loadSubcontractorInstallers(subcontractorId: number) {
    return api
      .get(`/subcontractors/${subcontractorId}/installers`)
      .then(({ data }) => {
        data.forEach((installer) => {
          subcontractorInstallers.value.set(installer.UserId, {
            userId: installer.UserId,
            name: installer.Name,
            mobile: installer.Mobile,
            email: installer.Email,
            tenantId: installer.TenantId,
          });
        });
      })
      .catch(genericApiErrorHandler);
  }

  type SubcontractorUser = {
    name: string;
    phone: number | null;
    email: string;
    autoMention: boolean;
    autoEmail: boolean;
  };

  const subcontractorUsers = ref<Map<number, Map<number, SubcontractorUser>>>(new Map());

  function loadSubcontractorUsers(subcontractorId: number) {
    return api
      .get(`/subcontractors/${subcontractorId}/users`)
      .then(({ data }) => {
        function mapPartnerUser(user: any): SubcontractorUser {
          return {
            name: user.Fullname,
            phone: user.Mobile,
            email: user.Email,
            autoMention: user.AutoMention,
            autoEmail: user.AutoEmail,
          };
        }

        subcontractorUsers.value.set(
          subcontractorId,
          new Map(data.map((u: object) => [u.UserId, mapPartnerUser(u)])),
        );
      })
      .catch(genericApiErrorHandler);
  }

  function loadEmployerSubcontractors(partnerId: number) {
    return api
      .get(`/employers/${partnerId}/subcontractors`)
      .then(({ data }) => new Map(data.map((s) => [s.Id, mapPartner(s)])))
      .catch(genericApiErrorHandler);
  }

  function loadGoldPartnersByTenantId(tenantId: number): Promise<GoldPartner[]> {
    return api
      .get(`/tenants/${tenantId}/goldpartners`)
      .then(({ data }) =>
        data.map((res) => ({
          id: res.Id,
          name: res.Name,
          permissionsFromEmployer: res.PermissionGuidsFromEmployer || [],
          permissionsFromSubcontractor: res.PermissionGuidsFromSubcontractor || [],
        })),
      )
      .catch(genericApiErrorHandler);
  }

  function loadRequestCategoryTenants(tenantId: number | null = null) {
    return api
      .get(`/requestcategoriesfavoritetenants${tenantId ? `?tenantId=${tenantId}` : ''}`)
      .then(({ data }) => {
        const mapped = new Map(data.map((rct) => [rct.Id, mapRequestCategoryTenant(rct)]));
        return mapped;
      })
      .catch(genericApiErrorHandler);
  }

  const typeGroups = ref<Map<number, TypeGroup>>(new Map());

  function loadTypeGroups({
    tenantIds = [],
    requestCategoryIds = [],
    maintenanceTypeIds = [],
  }: {
    tenantIds?: number[];
    requestCategoryIds?: number[];
    maintenanceTypeIds?: number[];
  } = {}): Promise<Map<number, TypeGroup>> {
    const queries = createQueryParams(
      new Map([
        ['tenantIds', tenantIds],
        ['requestCategoryIds', requestCategoryIds],
        ['maintenanceObjectTypeIds', maintenanceTypeIds],
      ]),
    );

    return api
      .get(`/typegroups?${queries}`)
      .then(({ data }) => {
        const mapped: Map<number, TypeGroup> = new Map(
          data
            .sort((a, b) => compare(a.Name, b.Name))
            .map((t) => [
              t.Id,
              {
                name: t.Name,
                requestCategoryIds: t.RequestCategoryIds,
                maintenanceTypeIds: t.MaintenanceObjectTypeIds,
                hasRequest: t.RequestCategoryIds.length > 0,
                hasMaintenance: t.MaintenanceObjectTypeIds.length > 0,
              },
            ]),
        );

        typeGroups.value = new Map([...typeGroups.value, ...mapped]);
        return typeGroups.value;
      })
      .catch(genericApiErrorHandler);
  }

  const articles = ref<Map<number, Article>>(new Map());

  function loadArticles() {
    return api
      .get(`articles`)
      .then(({ data }) => {
        data.forEach((a) => {
          articles.value.set(a.Id, mapArticle(a));
        });
      })
      .catch(genericApiErrorHandler);
  }

  const municipalities = ref<Map<number, { name: string }>>(new Map());

  function loadMunicipalities() {
    return api
      .get('/municipalities')
      .then(({ data }) => {
        return (municipalities.value = new Map(
          data.map((m) => [m.Id, { name: m.Name }]).sort((a, b) => compare(a[1].name, b[1].name)),
        ));
      })
      .catch(genericApiErrorHandler);
  }

  type TenantRole = { id: number; name: string };
  const tenantRoles = ref<Map<number, TenantRole>>(new Map());

  async function loadTenantRoles() {
    await awaitTenant;
    return api
      .get(`/Tenants/${tenant.value.id}/Roles`)
      .then(({ data }) => {
        tenantRoles.value = new Map(data.map((r) => [r.Id, { id: r.Id, name: r.Name }]));
      })
      .catch(genericApiErrorHandler);
  }

  return {
    tenant,
    loadTenant,
    updatePublicMessage,

    departments,
    loadDepartments,

    caseworkers,
    loadCaseworkers,
    loadCaseworkers2,

    installers,
    loadInstallers,

    users,
    loadUsers,

    gridowners,
    gridownerByTenantId,
    loadGridowners,

    employers,
    loadEmployers,
    subcontractors,
    loadSubcontractors,
    partners,
    loadPartners,

    tenantFavoriteUsers,
    loadTenantFavoriteUsers,
    addTenantFavoriteUser,
    updateTenantFavoriteUser,
    deleteTenantFavoriteUser,

    addSubcontractor,
    removeSubcontractor,

    loadSubcontractorById,
    subcontractorInstallers,
    loadSubcontractorInstallers,
    subcontractorUsers,
    loadSubcontractorUsers,
    loadRequestCategoryTenants,
    loadEmployerSubcontractors,

    selectablePermissions,
    hasPartnerPermission,

    addPermission,
    removePermission,

    typeGroups,
    loadTypeGroups,

    articles,
    loadArticles,

    municipalities,
    loadMunicipalities,

    tenantRoles,
    loadTenantRoles,

    loadGoldPartnersByTenantId,
  };
});
