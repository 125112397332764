import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useOneSignal } from '@onesignal/onesignal-vue3';
import { oneSignalAppId } from '/@tools/config';
import { awaitUser2, useUserStore } from '/@features/user';
import { hasRole } from '/@tools/general-utils';
import { Role } from '/@types/ids';

let resolveNotifications: (value?: unknown) => void;
export const awaitNotifications = new Promise((resolve) => (resolveNotifications = resolve));

const oneSignal = useOneSignal();

const permission = ref(false);

const permissionNative = ref<'default' | 'denied' | 'granted'>('default');

const optedIn = ref<boolean>();

export function useNotifications() {
  // onesignal plugin does not use refs so we have to manually update state
  function updateProps() {
    permission.value = oneSignal.Notifications.permission;
    permissionNative.value = oneSignal.Notifications.permissionNative;
    optedIn.value = oneSignal.User.PushSubscription.optedIn;
  }

  async function initialize() {
    await awaitUser2;

    const { user } = storeToRefs(useUserStore());

    if (!hasRole(user.value, [Role.AppOwner])) return;

    return oneSignal
      .init({ appId: oneSignalAppId })
      .then(() => {
        return oneSignal.login(user.value.guid);
      })
      .then(() => {
        updateProps();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        resolveNotifications();
      });
  }

  function logout() {
    return oneSignal
      .logout()
      .then(() => {
        updateProps();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function enablePush() {
    return oneSignal.Notifications.requestPermission()
      .then(() => {
        return oneSignal.User.PushSubscription.optIn();
      })
      .then(() => {
        updateProps();
        // onesignal promises does not catch this so we put this in .then
        if (permissionNative.value === 'denied') {
          throw new Error(`Device permission: ${permissionNative.value}`);
        }
      });
  }

  function disablePush() {
    return oneSignal.User.PushSubscription.optOut()
      .then(() => {
        updateProps();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return {
    initialize,
    logout,

    permission,
    permissionNative,
    optedIn,

    enablePush,
    disablePush,
  };
}
